import { IApiConfig } from "../Contracts/IApiConfig";

const hostname = window && window.location && window.location.hostname;
const app_Root = {} as IApiConfig;

if (hostname.indexOf("localhost") > -1) {
  app_Root.reportingHost ="https://strepapi.inn-flow.net/api";
  app_Root.backendHost = "https://stapiv2.inn-flow.net/api";
  //  app_Root.backendHost = "https://localhost:44385//api";
  app_Root.notificationHubURL = "https://stapiv2.inn-flow.net/hubs";
  app_Root.dwollaConfigure = "sandbox";
  //app_Root.dwollaConfigure = "prod";
} else {
  app_Root.environment = "staging";
  /** ******** For Reports Staging ******** **/
  app_Root.reportingHost = "https://strepapi.inn-flow.net/api";
  app_Root.backendHost = "https://stapiv2.inn-flow.net/api";
  app_Root.notificationHubURL = "https://stapiv2.inn-flow.net/hubs";
  app_Root.dwollaConfigure = "sandbox";
  //app_Root.dwollaConfigure = "prod";
}

export const API_ROOT = app_Root;
